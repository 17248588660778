import React from 'react'
import './styles.scss'

export default function Video({ videoSrcURL }) {
  return (
    <video controls autoPlay controlsList="nodownload" className="video-content">
      <source src={videoSrcURL} />
    </video>
  );
}

