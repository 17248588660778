import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"
import moment from "moment-timezone"
import { Carousel } from "react-responsive-carousel"

const SliderForPopup = ({ customClass, activeItem }) => {
  return (
    <Carousel
      showIndicators={activeItem.length > 1 ? true : false}
      showThumbs={false}
      autoPlay
      emulateTouch={activeItem.length > 1 ? true : false}
      infiniteLoop={activeItem.length > 1 ? true : false}
      showStatus={false}
      // interval={3300}
      transitionTime={1500}
    >
      {activeItem.map((item, index) => (
        <div
          className={`banner ${customClass}`}
        >
          <div className={`img-wrapper messgae-${item.popup_code}`}>
            <a className="popup-url" href={item.url_internal} target="_blank">
              <img src={item.image} alt={"Message"}/>
            </a>
          </div>

        </div>
      ))}

    </Carousel>

  )
}

export default SliderForPopup
