import React from 'react'
import './styles.scss'
import parse from "html-react-parser"
import Video from 'src/components/Video'

export default function VideoHomePage({ imageVideo, textVideoTitle, textVideoContent, locale }) {
  if (textVideoTitle == "") {
    return <></>
  }
  return (
    <div className="row-video-welcome">
      <div className="left-video-row">
        <img src={imageVideo} />
        {/* <Video videoSrcURL={} poster={videoImg}/> */}
      </div>
      <div className="right-text-video-row">
        <div className={`text-bold title-video-text ${locale}`}>{parse(textVideoTitle)}</div>
        <div className={`text-thin-italic content-video-text ${locale}`}>{parse(textVideoContent)}</div>
      </div>
    </div>
  )
}