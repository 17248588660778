import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
const Banner = ({ banners, customClass, hasRedBar }) => {
  const renderVideo = item => {
    return (
      <div style={{ position: 'relative' }}>
        <video
          playsInline="playsinline"
          controlsList="nodownload"
          controls={false}
          muted="muted"
          loop="loop"
          autoPlay="autoplay"
          // poster="images/main-banner.<%= @browser.safari? || @browser.safari_webapp_mode? ? 'jp2' : @browser.ie? ? 'jpg' : 'webp' %>"
        >
          <source src={item.video} type="video/mp4"></source>
        </video>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.6)'
          }}
        ></div>
        <div className={`banner-content ${customClass}`}>{item.content}</div>
      </div>
    )
  }
  return (
    <div
      className={`banner ${customClass}`}
      style={hasRedBar ? { marginBottom: 60 } : {}}
    >
      <Carousel
        showIndicators={banners.length > 1 ? true : false}
        showThumbs={false}
        autoPlay
        emulateTouch={banners.length > 1 ? true : false}
        infiniteLoop={banners.length > 1 ? true : false}
        showStatus={false}
        interval={6300}
        transitionTime={1500}
      >
        {banners.map((item, index) => (
          <div key={index}>
            <div>
              <img src={item} />
              {/* <img style={{ backgroundColor: 'white' }} src={item.image} />
                  <div className={`banner-content ${customClass}`}>
                    <img src={item} />
                  </div> */}
            </div>
          </div>
        ))}
      </Carousel>
      {hasRedBar && (
        <div className="red-bar-container">
          <div className="red-bar"></div>
        </div>
      )}
    </div>
  )
}

export default Banner
