import React from 'react'
import './styles.scss'

import parse from 'html-react-parser'

import red_circle from '../../images/prumdrt2020/red_circle.svg'
import title_background from 'src/images/prumdrt2020/title_bg.svg'

const AgendaLineItem = ({ sessions_text, programmes, bg_agenda_item, locale }) => {

  return (
    <div className="agenda-line-item">
      <div className="section-item">
        {programmes.map((datime) => (
          <div className="agenda-item">
            <p className="row-date date-bg"
              style={{ backgroundImage: `url(${title_background})` }}
            >
              {datime.agenda_date}
            </p>
            <p className="row-time">{datime.agenda_time}</p>
            <p className="text-sessions">{sessions_text}</p>
            {datime.sessions.map((item) => (
              <div className="item-subject">
                <p className={`normal-text agenda ${locale}`}>{parse(item.topic)}</p>
                {/* <p className="bold-text">{item.speaker}</p> */}
              </div>
            ))}
            <div className="bg-many-circle" style={{ backgroundImage: `url(${bg_agenda_item})` }}></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AgendaLineItem
